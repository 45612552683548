<template>
  <div class="mobile-layout">
    <MobileHeader />
    <slot />
    <Footer />
  </div>
</template>

<script>
import MobileHeader from '@/components/Header/MobileHeader'
import Footer from '@/components/Footer/Footer'
export default {
  name: 'MobileLayout',
  components: { Footer, MobileHeader }
}
</script>

<style lang="scss">
.mobile-layout {
  min-height: 100vh;
}
</style>
